var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.isShortView ? 'Short' : 'Full',{tag:"component",staticClass:"list-item",class:{
    'list-item_active': _vm.isActive,
    'list-item_selected': _vm.isSelected,
    'list-item_pin': _vm.chat.pinned,
    'list-item_full': !_vm.isShortView,
    'list-item_short': _vm.isShortView,
    'list-item_special': _vm.isFeedbackBot,
  },attrs:{"tag":_vm.link ? 'a' : 'div',"href":_vm.link,"clickable":!!_vm.link,"chat":_vm.chat,"is-afk":_vm.online && _vm.online.afk,"is-mobile":_vm.online && _vm.online.mobile,"is-online":!!_vm.online,"is-muted":_vm.isMuted,"last-message":_vm.lastMessage,"call-status":_vm.callStatus,"show-hide":_vm.isChatExists,"show-pin":_vm.isChatExists && !_vm.chat.hidden,"show-systembot":_vm.showSystembot,"systembot-notifications":_vm.systembotNotifications}})}
var staticRenderFns = []

export { render, staticRenderFns }