






























import { LastMessage } from '@/components/ChatsBar/types'
import { chatsBarStore, contactsStore, rootStore } from '@/store'
import { Chat, Contact, JID } from '@tada-team/tdproto-ts'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component({
  components: {
    Full: () => import('@/components/ChatsBar/List/Item/Full.vue'),
    Short: () => import('@/components/ChatsBar/List/Item/Short.vue'),
  },
})
export default class Item extends Vue {
  @Prop({ type: Object, required: true }) private readonly chat!: Chat
  @Prop({ type: Boolean }) private readonly isShortView?: boolean
  @Prop({ type: Boolean }) private readonly isSelected?: boolean

  @Getter currentChat!: JID | null
  @Getter profile!: Contact

  private get link () {
    if (this.isActive) return
    const { currentTeamId, location: { origin } } = window
    return `${origin}/${currentTeamId}/chats/${this.chat.jid}`
  }

  private get isActive () {
    return this.chat.jid === this.currentChat
  }

  private get isChatExists () {
    return chatsBarStore.state.chats.some(item => item.jid === this.chat.jid)
  }

  private get isFeedbackBot () {
    const contact = contactsStore.getters.contact(this.chat.jid)
    return contact?.botname === 'feedbackbot'
  }

  private get isMuted () {
    return this.showSystembot
      ? !this.systembotNotifications
      : !this.chat.notificationsEnabled
  }

  private get showSystembot () {
    const contact = contactsStore.getters.contact(this.chat.jid)
    return contact?.botname === 'systembot'
  }

  private get systembotNotifications () {
    // TODO: need to move this to backend
    return this.profile.systembotNotificationsEnabled ?? false
  }

  private get online () {
    const { onlineIds } = contactsStore.state
    return onlineIds.find(item => item.jid === this.chat.jid)
  }

  private get lastMessage (): LastMessage | undefined {
    if (this.isShortView || !this.chat.lastMessage) return

    const { chatType, from, pushText: text, received } = this.chat.lastMessage

    if (from === this.profile.jid) {
      return {
        text,
        sender: this.profile.shortName,
        status: received ? 'received' : 'sent', // TODO: sending status
      }
    }

    if (chatType === 'direct') return { text }

    const sender = contactsStore.getters.contact(from)?.shortName
    if (sender) return { text, sender }
    else return { text }
  }

  private get callStatus () {
    return rootStore.getters.callStatuses[this.chat.jid]
  }
}
